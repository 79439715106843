import { http, httpfb, httpfbnd } from "src/services";
import queryString from "query-string";

// Action Types
export const Types = {
  INVENTORY_LOADING: "inventory/loading",
  INVENTORY_SET_DATA: "inventory/set/data",
  INVENTORY_SET_PAGING_DATA: "inventory/set/paging/data",
  INVENTORY_SET_LIST: "inventory/set/list",
  INVENTORY_SET_PAGING_LIST: "inventory/set/paging/list",
  INVENTORY_SET_CHECKED: "inventory/set/checked",
  INVENTORY_SET_CONFERRED: "inventory/set/conferred",
  INVENTORY_SET_PAGING_CONFERRED: "inventory/set/paging/conferred",
  INVENTING_SET_DATA_LIST: "inventorying/set/data/list",
  INVENTING_SET_DATA_DETAIL: "inventorying/set/data/detail",
  INVENTORY_SET_LIST_FILTERS: "inventory/set/list/filters",
  INVENTORY_GET_STATUS: "inventory/get/status",
};

// Reducer
const initialState = {
  loading: false,
  inventorying_data: [],
  inventorying_data_paging: { limit: 25, offset: 0, total: 0 },
  inventory_detail: [],
  inventory_data: {
    address_key: "",
    items: [],
  },
  inventory_status_list: [],
  inventory_list: [],
  inventory_list_paging: { limit: 25, offset: 0, total: 0 },
  inventory_list_filters: {
    type: "",
    description: "",
    status: 1,
  },
  inventory_checked: [],
  inventory_checked_paging: { limit: 25, offset: 0, total: 0 },
  inventory_conferred: [],
  inventory_conferred_paging: {
    limit: 25,
    offset: 0,
    total: 0,
    pendent: 0,
    notPendent: 0,
  },
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.INVENTORY_GET_STATUS:
      return { ...state, inventory_status_list: action.payload };
    case Types.INVENTORY_SET_LIST_FILTERS:
      return { ...state, inventory_list_filters: action.payload };
    case Types.INVENTING_SET_DATA_LIST:
      return { ...state, inventorying_data: action.payload };
    case Types.INVENTING_SET_DATA_DETAIL:
      return { ...state, inventory_detail: action.payload };
    case Types.INVENTORY_LOADING:
      return { ...state, loading: action.payload };
    case Types.INVENTORY_SET_DATA:
      return { ...state, inventory_data: action.payload };
    case Types.INVENTORY_SET_PAGING_DATA:
      return { ...state, inventorying_data_paging: action.payload };
    case Types.INVENTORY_SET_LIST:
      return { ...state, inventory_list: action.payload };
    case Types.INVENTORY_SET_PAGING_LIST:
      return { ...state, inventory_list_paging: action.payload };
    case Types.INVENTORY_SET_CHECKED:
      return { ...state, inventory_checked: action.payload };
    case Types.INVENTORY_SET_CONFERRED:
      return { ...state, inventory_conferred: action.payload };
    case Types.INVENTORY_SET_PAGING_CONFERRED:
      return { ...state, inventory_conferred_paging: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  inventoryGetListStatus: (status) => ({
    type: Types.INVENTORY_GET_STATUS,
    payload: status,
  }),
  inventoryListSetFilters: (filters) => ({
    type: Types.INVENTORY_SET_LIST_FILTERS,
    payload: filters,
  }),
  inventoryLoading: (value) => ({
    type: Types.INVENTORY_LOADING,
    payload: value,
  }),
  inventoryingSetDataList: (value) => ({
    type: Types.INVENTING_SET_DATA_LIST,
    payload: value,
  }),
  inventoryingSetDataDetail: (value) => ({
    type: Types.INVENTING_SET_DATA_DETAIL,
    payload: value,
  }),
  inventorySetData: (value) => ({
    type: Types.INVENTORY_SET_DATA,
    payload: value,
  }),
  inventorySetPagingData: (value) => ({
    type: Types.INVENTORY_SET_PAGING_DATA,
    payload: value,
  }),
  inventorySetList: (value) => ({
    type: Types.INVENTORY_SET_LIST,
    payload: value,
  }),
  inventorySetPagingList: (value) => ({
    type: Types.INVENTORY_SET_PAGING_LIST,
    payload: value,
  }),
  inventorySetChecked: (value) => ({
    type: Types.INVENTORY_SET_CHECKED,
    payload: value,
  }),
  inventorySetConferred: (value) => ({
    type: Types.INVENTORY_SET_CONFERRED,
    payload: value,
  }),
  inventorySetPagingConferred: (value) => ({
    type: Types.INVENTORY_SET_PAGING_CONFERRED,
    payload: value,
  }),
  setInventoryingConcluded: (body) => {
    return new Promise((resolve, reject) => {
      httpfb
        .put(
          `/inventories/${body.items[0].inventory_id}/item/${body.items[0].inventory_item_id}/inventoried-position`,
          body,
          {
            timeout: 1000 * 60,
          }
        )
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getInventoryingDetail: (inventory_id, address_id) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/inventory/${inventory_id}/inventorying/detail/${address_id}`, {
          timeout: 1000 * 60,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getInventoryingList: (filters) => {
    const qry = queryString.stringify(filters, { arrayFormat: "index" });
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/inventory/inventorying/list?${qry}`, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  setInventoryingUser: ({ id: inventory_id, address_id, type = "ADD" }) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .patch(
          `/inventory/${inventory_id}/inventorying/address/${address_id}`,
          { type },
          {
            timeout: 1000 * 60,
          }
        )
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  delInventoryItem: ({ inventory_id, id }) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .delete(`/inventory/${inventory_id}/item/delete/${id}`, {
          timeout: 1000 * 60,
        })
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  },
  setInventory: (body) => {
    return new Promise((resolve, reject) => {
      http
        .post(`/stock/inventory/free`, body, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getInventoryList: (filters) => {
    const qry = queryString.stringify(filters, { arrayFormat: "index" });
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/inventory/list?${qry}`, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  checkIfInventoryIsValid: (inventory_id) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/inventory/${inventory_id}/isvalid`, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getInventoryProductsConferred: (inventory_id, filters = {}) => {
    const qry = queryString.stringify(filters, { arrayFormat: "index" });
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/inventory/${inventory_id}/products/conferred?${qry}`, {
          timeout: 1000 * 60,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  postInventoryProductsAddress: ({ inventory_id, address_id }) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .post(
          `/inventory/${inventory_id}/add/address`,
          { address_id },
          {
            timeout: 1000 * 60,
          }
        )
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  putInventoryItemRejected: ({ inventory_id, item }) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .put(`/inventory/${inventory_id}/item/reject`, item, {
          timeout: 1000 * 60,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  putInventoryItemAccept: ({ inventory_id, item }) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .put(`/inventory/${inventory_id}/item/accept`, item, {
          timeout: 1000 * 60,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  postInventoryProductsVariations: ({
    inventory_id,
    ids,
    stock_id,
    position_id,
  }) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .post(
          `/inventory/${inventory_id}/add/variations`,
          { ids, stock_id, position_id },
          {
            timeout: 1000 * 60,
          }
        )
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  postInventorySave: (body) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .post(`/inventory/create`, body, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  delInventoryId: (id) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .delete(`/inventory/delete/${id}`, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  cancelInventoryId: (id) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .patch(`/inventory/cancel/${id}`, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  concludeInventoryId: (id) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .patch(`/inventory/conclude/${id}`, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  sendResetERP: (body) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .post(`/inventory/adjust/reset/inventory`, body, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  sendWmsStockToERP: (body) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .post(`/inventory/adjust/wms/to/erp`, body, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getListStatus: (body) => {
    return new Promise((resolve, reject) => {
      httpfbnd
        .get(`/inventory/status`, body, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};
