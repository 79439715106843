import { Button, IconButton, InputAdornment } from "@mui/material";
import { Keyboard, KeyboardHide } from "@mui/icons-material";
import React from "react";
import { Camera, CameraOff } from "react-feather";
import { getMobileOperatingSystem } from "src/utils/getUtils";
import BarcodeReaderCam from "../BarcodeReaderCamera";
import InputText from "../InputText";
import Modal from "../Modal";

function InputBarcode({
  placeholder = "",
  value,
  onChange,
  onKeyDown,
  inputMode = "text",
  onClickCam,
  statusCam = false,
  onClickKeyboard,
  statusKeyboard = false,
  readOnlyKeyboard = false,
  callbackBarcode,
  inputRef,
  isEan,
  autoComplete = "off",
}) {
  return (
    <>
      <Modal
        width={getMobileOperatingSystem() === "unknown" ? 700 : "100%"}
        height={getMobileOperatingSystem() === "unknown" ? 550 : "60%"}
        open={statusCam}
      >
        <BarcodeReaderCam
          callback={callbackBarcode}
          width={"100%"}
          height={"90%"}
          isEan={isEan}
        />
        <div style={{ margin: 5 }}>
          <Button onClick={onClickCam}>Fechar</Button>
        </div>
      </Modal>
      <InputText
        autoFocus
        inputRef={inputRef}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange({
            target: {
              value: e.target.value
                ?.trim()
                .match(/[A-Za-z0-9\-\_\.\+\:\;\,\/\%\$\*\(\)\!\?\@\=\&]+/g)
                ?.join('') || '',
            },
          });
        }}
        onKeyDown={onKeyDown}
        inputMode={inputMode}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label={statusCam ? "Fechar câmera" : "Abrir câmera"}
                onClick={onClickCam}
              >
                {statusCam ? <Camera /> : <CameraOff />}
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Habilitar teclado do dispositivo móvel"
                onClick={onClickKeyboard}
              >
                {statusKeyboard ? <Keyboard /> : <KeyboardHide />}
              </IconButton>
            </InputAdornment>
          ),
          readOnly: readOnlyKeyboard
        }}
        autoComplete={autoComplete}
      />
    </>
  );
}

export default InputBarcode;
